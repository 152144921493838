import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import NotificationsList from "../components/organisms/NotificationsList/NotificationsList"

const Notifications = () => (
    <Layout>
        <SEO title="Powiadomienia" />
        <NotificationsList />
    </Layout>
)

export default Notifications
