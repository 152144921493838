import React from "react"

import Icon from "../../atoms/Icon/Icon"

import "./notificationsListItem.scss"

const NotificationsListItem = ({ heading, desc, isNew }) => {
    return (
        <div
            className={`notification-list-item ${isNew ? "notification-list-item__new" : ""
                }`}
        >
            <div
                className={`notification-list-item--icon ${isNew ? "notification-list-item--icon__new" : ""
                    }`}
            >
                {isNew ? "N" : <Icon className="icon-checkmark-min" />}
            </div>

            {heading && <h3 className="notification-list-item--title">{heading}</h3>}

            {desc && <p className="notification-list-item--desc">{desc}</p>}
        </div>
    )
}

export default NotificationsListItem
